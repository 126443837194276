<template lang="pug">
  div.course-row
    div.course-wrapper
      div.course-titles(:class="{'archive': !course.isLive}")
        p(v-if="!courseDeleted") Course:
          span(v-if="course.course._date !== 'None'") {{ course.course.dateAndName }} {{ courseCity.name }}
          span(v-else) {{ course.course.name }} {{ courseCity.name }}
        p(v-else) Course: Deleted
        p Exam: {{ course.examDate }} ({{ course.timeFrom }}-{{ course.timeTo }}) {{ course.location }}
          span.exchange-label(v-if="course.isExchange") exchange
      v-checkbox(v-if="!isCourseInPastDay && !course.isLive && !courseDeleted" @click="$emit('onCheck', course)")
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'

export default {
  props: {
    course: {
      type: Object,
      required: true,
      default: () => {
      }
    }
  },
  computed: {
    ...mapGetters({
      cities: 'crmCourseCities/items',
    }),
    courseDeleted() {
      return Object.keys(this.course.course).length === 0
    },
    courseCity() {
      if (this.cities.length && !this.courseDeleted) {
        return this.cities.find(city => city.ID === this.course.course.city)
      } else {
        return {}
      }
    },
    isCourseInPastDay() {
      if (dayjs(this.course.course.originDate).isSame(dayjs(), 'day')) return false
      return dayjs(this.course.course.originDate).isBefore(dayjs(), 'day')
    }
  }
}
</script>

<style lang="scss" scoped>

.course-row {
  font-size: 12px;
}

.course-wrapper {
  width: 100%;
  margin-top: 8px;
  border: 1px solid #eaebf1;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.course-titles {
  p {
    margin-bottom: 0;
    letter-spacing: normal;
  }

  &.archive {
    opacity: .5;
  }
}

.exchange-label {
  padding: 3px 6px;
  border-radius: 8px;
  background-color: $warning-color;
  margin-left: 5px;
}

</style>
