<template lang="pug">
  v-card.courses-history(v-if="courses.length")
    v-card-title
      v-btn(
        color="primary"
        :disabled="!checkedCourses.length"
        @click="makeActive"
      ) Make Active
    v-card-text
      div.courses-history-item(v-if="appliedCourses.length")
        h3.courses-history-item__title Applied
        candidate-course-history-item(v-for="(course, index) in appliedCoursesSorted" :key="`applied-${course.course.ID}-${index}`" :course="course" @onCheck="onCheckHandler")
      div.courses-history-item(v-if="suggestedConfirmedCourses.length")
        h3.courses-history-item__title Suggest Confirmed
        candidate-course-history-item(v-for="(course, index) in suggestedConfirmedCoursesSorted" :key="`confirmed-${course.course.ID}${index}`" :course="course" @onCheck="onCheckHandler")
      div.courses-history-item(v-if="addedManuallyCourses.length")
        h3.courses-history-item__title Add manually
        candidate-course-history-item(v-for="(course, index) in addedManuallyCoursesSorted" :key="`manually-${course.course.ID}${index}`" :course="course" @onCheck="onCheckHandler")
      div.courses-history-item(v-if="quickReservedCourses.length")
        h3.courses-history-item__title Quick reserve
        candidate-course-history-item(v-for="(course, index) in quickReservedCoursesSorted" :key="`quick-${course.course.ID}${index}`" :course="course" @onCheck="onCheckHandler")

</template>

<script>
  import { ORIGINS } from '../../core/candidates-const'
  import CandidateCourseHistoryItem from './CandidateCourseHistoryItem.vue'

  export default {
    props: {
      courses: {
        type: Array,
        required: true,
      }
    },
    data: () => ({
      checkedCourses: []
    }),
    components: {
      CandidateCourseHistoryItem
    },
    computed: {
      appliedCourses() {
        return this.courses.filter(course => course.origin === ORIGINS.APPLICATION)
      },
      appliedCoursesSorted() {
        return this.appliedCourses.sort((a, b) => new Date(a.course.originDate) - new Date(b.course.originDate))
      },
      suggestedConfirmedCourses() {
        return this.courses.filter(course => course.origin === ORIGINS.CONFIRMED_SUGGESTION)
      },
      suggestedConfirmedCoursesSorted() {
        return this.suggestedConfirmedCourses.sort((a, b) => new Date(a.course.originDate) - new Date(b.course.originDate))
      },
      addedManuallyCourses() {
        return this.courses.filter(course => course.origin === ORIGINS.ADDED_MANUALLY)
      },
      addedManuallyCoursesSorted() {
        return this.addedManuallyCourses.sort((a, b) => new Date(a.course.originDate) - new Date(b.course.originDate))
      },
      quickReservedCourses() {
        return this.courses.filter(course => course.origin === ORIGINS.QUICK_RESERVE)
      },
      quickReservedCoursesSorted() {
        return this.quickReservedCourses.sort((a, b) => new Date(a.course.originDate) - new Date(b.course.originDate))
      }
    },
    methods: {
      onCheckHandler(checkedCourse) {
        this.checkedCourses.map(course => course.course.ID).includes(checkedCourse.course.ID) ?
          this.checkedCourses = this.checkedCourses.filter(course => course.course.ID !== checkedCourse.course.ID) :
          this.checkedCourses.push(checkedCourse)
      },
      makeActive() {
        this.courses.forEach((course, index) => {
          if (this.checkedCourses.map(c => c.course.ID).includes(course.course.ID)) {
            this.courses[index].isLive = true;
          }
        });
        this.$emit('makeActive', this.checkedCourses);
        this.checkedCourses = [];
      }
    }
  }
</script>

<style lang="scss" scoped>

  .courses-history{
    margin-top: 16px;

    &-item{
      margin-top: 8px;

      &__title{
        margin-bottom: 8px;
      }
    }
  }

</style>